<template>
  <v-dialog v-model="show_dialog" persistent width="360">
    <v-card class="modern-loader">
      <v-card-text class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
          width="5"
          class="animated-loader mt-5"
        ></v-progress-circular>
        <p class="mt-4 text-white font-weight-bold text-body-1">
          Loading page, please wait...
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageRedirectLoaderDialog",
  computed: {
    ...mapState({
      show_dialog: state => state.is_redirecting
    })
  }
};
</script>

<style scoped>
/* Glassmorphism effect */
.modern-loader {
  padding: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  backdrop-filter: blur(12px);
}

/* Subtle fade-in animation */
.v-dialog {
  transition: opacity 0.4s ease-in-out, transform 0.3s ease-in-out;
}

/* Loader pulse effect */
.animated-loader {
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
