<template>
  <v-app
    id="app"
    :class="
      `${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`
    "
  >
    <router-view />

    <PageRedirectLoaderDialog />

    <PageLoaderDialog />
  </v-app>
</template>

<script>
import EventBus from "@/eventBus";
import PageRedirectLoaderDialog from "@/components/Dialogs/PageRedirectLoaderDialog";
import PageLoaderDialog from "@/components/Dialogs/PageLoaderDialog.vue";
import { Logger } from "@/helpers/logger";

export default {
  name: "App",
  components: {
    PageRedirectLoaderDialog,
    PageLoaderDialog
  },
  data() {
    return {
      project_type_notifications: [1, 6, 9, 10, 11, 12, 13, 14]
    };
  },
  mounted() {
    this.$echo
      .channel("pzse.push.notifications")
      .listen(".PzseNotifications", payload => {
        EventBus.$emit("reloadNotifications");

        if (
          this.project_type_notifications.includes(
            payload.notificationVariables.ACTION_TYPE
          )
        ) {
          EventBus.$emit(
            "reloadProject",
            payload.notificationVariables.PROJECT_NUMBER
          );
        }
        Logger("Pusher", payload);
      });
  }
};
</script>

<style lang="scss">
@import "~dropzone/src/dropzone";
@import "./scss/theme";
@import "./assets/fonts/css/satoshi.css";

.theme--light {
  &.v-application {
    background-color: map-get($custom-colors, "alabaster") !important;
  }
}
</style>
