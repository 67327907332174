import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

Vue.prototype.$axios = axios;
Vue.prototype.$_ = _;
Vue.prototype.$moment = moment;
Vue.prototype.$dropzone = dropzone;
Vue.prototype.$dropzone.autoDiscover = false;

import config from "@/config";
import api from "@/api";

Vue.prototype.$config = config;
Vue.prototype.$api = api;

const requireComponent = require.context(
  // The relative path of the components' folder.
  "@/components/Base",
  // Whether to look in folders.
  false,
  // The regular expression used to match base component filenames.
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  // Get component config.
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component.
  const componentName = _.upperFirst(
    _.camelCase(
      // Gets the file name regardless of folder depth.
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally.
  Vue.component(
    componentName,
    /**
     * Look for the component options on `.default`, which will
     * exist if the component was exported with `export default`,
     * otherwise fall back to module's root.
     */
    componentConfig.default || componentConfig
  );
});

/**
 * Echo exposes an expressive AtcClient for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

import VueEcho from "vue-echo-laravel";

const EchoInstance = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
  cluster: "us2",
  forceTLS: false,
  namespace: "App.Events.Notifications"
});

Vue.use(VueEcho, EchoInstance);
