<template>
  <v-dialog v-model="show_dialog" persistent no-click-animation width="400">
    <v-card class="rounded-lg" elevation="0">
      <v-card-text class="pa-0">
        <div class="pa-10 text-center font-weight-medium">
          <v-progress-circular
            width="6"
            size="40"
            indeterminate
            color="grey darken-3"
          ></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from "@/eventBus";

export default {
  name: "PageLoaderDialog",
  data: () => ({
    show_dialog: false
  }),
  created() {
    const _this = this;

    EventBus.$on("showPageLoaderDialog", () => (_this.show_dialog = true));

    EventBus.$on("hidePageLoaderDialog", () => (_this.show_dialog = false));
  }
};
</script>

<style scoped></style>
